import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_OFFER_USPS_MANAGE } from 'Consts/routes';

import { parseQueryToObject } from 'Utils/querystring';
import { filterKeys } from 'Utils/object';
import { withVariables } from 'Utils/string';
import { getName as getOfferName } from 'Utils/offer';

import StyledComponent from 'Components/core/StyledComponent';
import PaginatedList from 'Components/layoutAdmin/panel/PaginatedList';
import PaginatedListElement from 'Components/layoutAdmin/panel/PaginatedListElement';

export default class PanelOfferUspsList extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            list: PropTypes.func.isRequired,
            setOrder: PropTypes.func.isRequired,
        }).isRequired,
        offerUsps: PropTypes.object.isRequired,
        predefinedQuery: PropTypes.object,
    };
    static defaultProps = {
        predefinedQuery: {},
    };

    defaultQuery = {
        page: 1,
        search: '',
        orderBy: '',
        timestamp: undefined,
        ...this.props.predefinedQuery,  //eslint-disable-line react/destructuring-assignment
    };

    getQueryConfig = (props = this.props) => {
        const { location } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            queryObject,
            Object.keys(this.defaultQuery)
        );
    }

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const previousQueryObject = this.getQueryConfig(prevProps);
        const queryObject = this.getQueryConfig();

        if (JSON.stringify(previousQueryObject) !== JSON.stringify(queryObject)) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();

        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
            actions.list({ ...queryObject });
        }, 500);
    }

    onSetOrder = (id, order) => {
        const { actions } = this.props;

        actions.setOrder({ id, order: order < 0 ? 0 : order })
            .then(this.loadData);
    }

    render() {
        const { offerUsps, location, history } = this.props;

        return (
            <StyledComponent
                className="panel-offer-usps-list"
                styles={require('./styles')}
            >
                <PaginatedList
                    location={location}
                    history={history}
                    collection={offerUsps}
                    title="Lista USP oferty"
                    queryConfig={this.getQueryConfig()}
                    onMapElement={element => (
                        <PaginatedListElement
                            key={element.id}
                            title={element.content}
                            subtitle={element.offer && getOfferName(element.offer).label || 'Brak'}
                            additionals={[{
                                name: 'Pozycja',
                                value: element.order,
                            }]}
                            controls={[{
                                type: 'button',
                                label: 'Przenieś wyżej',
                                visible: element.order > 0,
                                onClick: () => this.onSetOrder(element.id, element.order - 1),
                            }, {
                                type: 'button',
                                label: 'Przenieś niżej',
                                visible: true,
                                onClick: () => this.onSetOrder(element.id, element.order + 1),
                            }, {
                                type: 'button',
                                label: 'Zarządzaj',
                                visible: true,
                                to: withVariables(ADMIN_OFFER_USPS_MANAGE.path, { id: element.id }),
                            }]}
                        />
                    )}
                    filters={[
                        {
                            name: 'search',
                            label: 'Szukaj',
                            type: 'text',
                        }]}
                />
            </StyledComponent>
        );
    }
}
