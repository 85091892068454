import { connect } from 'react-redux';
import Component from './component';

import {
    listAllConversationUsers,
    enableConversation,
    disableConversation,
} from 'Redux/modules/admin/chat/actions';

export default connect(
    state => ({
        conversationUsersList: state.adminChat.conversationUsersList,
    }),
    dispatch => ({
        actions: {
            listAllConversationUsers: dispatch(listAllConversationUsers),
            enableConversation: dispatch(enableConversation),
            disableConversation: dispatch(disableConversation),
        },
    })
)(Component);
