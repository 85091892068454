import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { USER_ROLE_ADEPT, USER_ROLE_TRAINER } from 'Consts/userRoles';
import { ADMIN_CHAT_CONVERSATIONS } from 'Consts/routes';

import { parseQueryToObject } from 'Utils/querystring';
import { filterKeys } from 'Utils/object';
import { withVariables } from 'Utils/string';

import StyledComponent from 'Components/core/StyledComponent';
import PaginatedList from 'Components/layoutAdmin/panel/PaginatedList';
import PaginatedListElement from 'Components/layoutAdmin/panel/PaginatedListElement';

export default class PanelChatConversationsUsersList extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            listAllConversationUsers: PropTypes.func.isRequired,
            enableConversation: PropTypes.func.isRequired,
            disableConversation: PropTypes.func.isRequired,
        }).isRequired,
        conversationUsersList: PropTypes.shape({
            isLoaded: PropTypes.bool.isRequired,
            elements: PropTypes.array.isRequired,
        }),
    };
    static defaultProps = {
        conversationUsersList: null,
    };

    defaultQuery = {
        page: 1,
        search: '',
        role: '',
        orderBy: '',
        timestamp: undefined,
    };

    getQueryConfig = (props = this.props) => {
        const { location } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            queryObject,
            Object.keys(this.defaultQuery)
        );
    }

    componentDidMount = () => {
        this.loadConversations();
    }

    componentDidUpdate = prevProps => {
        const previousQueryObject = this.getQueryConfig(prevProps);
        const queryObject = this.getQueryConfig();

        if (JSON.stringify(previousQueryObject) !== JSON.stringify(queryObject)) {
            this.loadConversations();
        }
    }

    loadConversations = () => {
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();

        actions.listAllConversationUsers({ ...queryObject });
    }

    render() {
        const { conversationUsersList, location, history } = this.props;

        return (
            <StyledComponent
                className="chat-conversations-list"
                styles={require('./styles')}
            >
                <PaginatedList
                    location={location}
                    history={history}
                    collection={conversationUsersList}
                    title="Lista użytkowników"
                    queryConfig={this.getQueryConfig()}
                    onMapElement={element => (
                        <PaginatedListElement
                            key={element.id}
                            title={`${element.name} ${element.surname}`}
                            subtitle={`Rola: ${element.role}`}
                            additionals={[{
                                name: 'Konwersacji',
                                value: element.conversationsCount,
                            }]}
                            controls={[{
                                type: 'button',
                                label: 'Zobacz konwersacje',
                                visible: true,
                                to: withVariables(ADMIN_CHAT_CONVERSATIONS.path, {}, { tab: 'list', userId: element.id }),
                            }]}
                        />
                    )}
                    filters={[
                        {
                            name: 'search',
                            label: 'Szukaj',
                            type: 'text',
                        }, {
                            name: 'role',
                            label: 'Rola',
                            type: 'select',
                            inputProps: {
                                options: [{
                                    label: 'Wszystkie',
                                    value: '',
                                }, {
                                    label: 'Adept',
                                    value: USER_ROLE_ADEPT,
                                }, {
                                    label: 'Trener',
                                    value: USER_ROLE_TRAINER,
                                }],
                            },
                        }, {
                            name: 'orderBy',
                            label: 'Sortuj',
                            type: 'select',
                            inputProps: {
                                options: [{
                                    label: 'Ostatnio utworzeni',
                                    value: '',
                                }],
                            },
                        },
                    ]}
                />
            </StyledComponent>
        );
    }
}
