import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { API_RESOURCE_OFFER, API_RESOURCE_INVOICING_STRATEGIES } from 'Consts/apiResources';
import { 
    OFFER_TYPES, 
    OFFER_VISIBILITIES, 
    OFFER_DURATION_TYPES, 
    OFFER_PERIOD_TYPES, 
    OFFER_PERIOD_TYPE_RECURRING, 
    OFFER_FIRST_PAYMENT_METHOD_TYPES,
} from 'Consts/offers';
import { ADMIN_OFFERS_MANAGE, PUBLIC_SHOP_OFFER, ADMIN_OFFERS } from 'Consts/routes';
import { ENDPOINT_ADMIN_OFFER_PRESIGN_BACKGROUND } from 'Consts/api';

import { withVariables } from 'Utils/string';
import { formatPrice } from 'Utils/math';
import { fromSelectObject } from 'Utils/object';
import { getPeriodTypeLabel } from 'Utils/offer';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layoutAdmin/Spinner';
import ElementEditor from 'Components/layoutAdmin/panel/ElementEditor';
import ConversationsUsersList from 'Components/admin/chat/ConversationsUsersList/index';

export default class PanelOffersEditor extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            create: PropTypes.func.isRequired,
            update: PropTypes.func.isRequired,
            setEnabledStatus: PropTypes.func.isRequired,
            listInvoicingStrategies: PropTypes.func.isRequired,
            presignFile: PropTypes.func.isRequired,
            deleteOffer: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object,
    };
    static defaultProps = {
        data: null,
    };

    state = {
        formState: {},
    };

    componentDidMount = () => {
        const { data } = this.props;

        this.setState(prevState => ({
            formState: {
                ...prevState.formState,
                ...this.dataToFormState(data || {}),              
            },
        }));
    }

    componentDidUpdate = prevProps => {
        const { data } = this.props;

        if (data && JSON.stringify(data) !== JSON.stringify(prevProps.data)) {
            this.setState(prevState => ({
                formState: {
                    ...prevState.formState,
                    ...this.dataToFormState(data || {}),
                },
            }));
        }
    }

    dataToFormState = data => ({
        ...data,
        periodType: { value: data.periodType, label: getPeriodTypeLabel(data).label },
        invoicingStrategyId: data.invoicingStrategy
            ? { value: data.invoicingStrategy.id, label: `${data.invoicingStrategy.name} (${data.invoicingStrategy.description})` }
            : null,
    })

    onSubmit = formState => {
        const { data } = this.props;

        return data && data.id
            ? this.onUpdate(formState)
            : this.onCreate(formState);
    }

    onCreate = formState => {
        const { actions, history } = this.props;

        return actions.create({
            ...formState,
            type: fromSelectObject(formState.type),
            periodType: fromSelectObject(formState.periodType),
            visibility: fromSelectObject(formState.visibility),
            durationType: fromSelectObject(formState.durationType),
            noticePeriodDurationType: fromSelectObject(formState.noticePeriodDurationType),
            invoicingStrategyId: fromSelectObject(formState.invoicingStrategyId),
            firstPaymentMethodType: fromSelectObject(formState.firstPaymentMethodType),
        })
            .then(response => {
                history.push(
                    withVariables(
                        ADMIN_OFFERS_MANAGE.path,
                        { id: response.payload[API_RESOURCE_OFFER].id }
                    )
                );
            });
    }
    onUpdate = formState => {
        const { data, actions } = this.props;

        return actions.update({
            ...formState,
            id: data.id,
            type: fromSelectObject(formState.type),
            periodType: fromSelectObject(formState.periodType),
            visibility: fromSelectObject(formState.visibility),
            durationType: fromSelectObject(formState.durationType),
            noticePeriodDurationType: fromSelectObject(formState.noticePeriodDurationType),
            invoicingStrategyId: fromSelectObject(formState.invoicingStrategyId),
            firstPaymentMethodType: fromSelectObject(formState.firstPaymentMethodType),
        });
    }

    render() {
        const { data, location, history, actions } = this.props;
        const { formState } = this.state;
        
        if (!formState) {
            return (<Spinner />);
        }

        return (
            <StyledComponent
                className="panel-offers-editor"
                styles={require('./styles')}
            >
                <ElementEditor
                    location={location}
                    history={history}
                    details={[{
                        visible: Boolean(data),
                        label: 'Identyfikator',
                        value: formState.id,
                    }, {
                        visible: Boolean(data),
                        label: 'Slug',
                        value: data && data.slug,
                    }, {
                        visible: Boolean(data),
                        label: 'Cena brutto',
                        value: data && formatPrice(data.totalPriceGross),
                    }, {
                        visible: Boolean(data),
                        label: 'Link do sklepu',
                        value: data && process.env.APP_URL + withVariables(PUBLIC_SHOP_OFFER.path, { slug: data.slug }),
                        to: data && withVariables(PUBLIC_SHOP_OFFER.path, { slug: data.slug }),

                    }]}
                    controls={[{
                        visible: Boolean(data && data.id && data.enabled),
                        title: 'Wyłącz ofertę',
                        subtitle: 'Oferta nie będzie dostępna dla użytkowników',
                        buttonProps: {
                            onClick: () => actions.setEnabledStatus({ id: data && data.id, enabled: false }),
                            children: 'Wyłącz',
                        },
                    }, {
                        visible: Boolean(data && data.id && !data.enabled),
                        title: 'Włącz ofertę',
                        subtitle: 'Oferta będzie dostępna dla użytkowników',
                        buttonProps: {
                            onClick: () => {
                                actions.setEnabledStatus({ id: data && data.id, enabled: true })
                                    .catch(error => toast('Aby oferta została aktywowana musi ona posiadać chociaż jeden produkt'));
                            },
                            children: 'Włącz',
                        },
                    }, {
                        visible: Boolean(data && data.id && data.canDelete && !data.enabled),
                        title: 'Usuń ofertę',
                        subtitle: 'Oferta zostanie usunięta',
                        buttonProps: {
                            onClick: () => {
                                actions.deleteOffer({ id: data.id })
                                    .then(() => {
                                        history.push(ADMIN_OFFERS.path);
                                    })
                                    .catch(error => {
                                        Object.keys(error.payload.validationErrors).map(fieldName => {
                                            error.payload.validationErrors[fieldName].map(errorMessage => {
                                                toast.error(`${errorMessage}`);
                                            });
                                        });
                                    });
                            },
                            children: 'Usuń',
                        },
                    }]}
                    forms={[{
                        title: 'Dane',
                        name: 'editor',
                        submitAction: this.onSubmit,
                        data: formState,
                        onStateChange: formState => {
                            this.setState({
                                formState,
                            });
                        },
                        elements: [{
                            type: 'input',
                            name: 'name',
                            label: 'Nazwa',
                        }, {
                            isVisible: Boolean(data),
                            type: 'input',
                            name: 'displayName',
                            label: 'Nazwa wyświetlana',
                        }, {
                            type: 'textarea',
                            name: 'description',
                            label: 'Opis',
                        }, {
                            type: 'select',
                            name: 'type',
                            label: 'Typ oferty',
                            options: OFFER_TYPES.map(type => ({
                                value: type.key,
                                label: type.label,
                            })),
                        }, {
                            type: 'select',
                            name: 'periodType',
                            label: 'Cykliczność',
                            options: OFFER_PERIOD_TYPES.map(periodType => ({
                                value: periodType.key,
                                label: periodType.label,
                            })),
                        }, {
                            type: 'select',
                            name: 'visibility',
                            label: 'Widoczność',
                            options: OFFER_VISIBILITIES.map(visibility => ({
                                value: visibility.key,
                                label: visibility.label,
                            })),
                        }, {
                            isVisible: Boolean(fromSelectObject(formState.periodType) === OFFER_PERIOD_TYPE_RECURRING),
                            type: 'select',
                            name: 'durationType',
                            label: 'Cykl płatności (Typ)',
                            options: OFFER_DURATION_TYPES.map(durationType => ({
                                value: durationType.key,
                                label: durationType.label,
                            })),
                        }, {
                            isVisible: Boolean(fromSelectObject(formState.periodType) === OFFER_PERIOD_TYPE_RECURRING),
                            type: 'input',
                            name: 'durationValue',
                            label: 'Cykl płatności (Wartość)',
                            inputProps: {
                                type: 'number',
                            },
                        }, {
                            isVisible: Boolean(fromSelectObject(formState.periodType) === OFFER_PERIOD_TYPE_RECURRING),
                            type: 'select',
                            name: 'noticePeriodDurationType',
                            label: 'Czas trwania wypowiedzenia (Typ)',
                            options: OFFER_DURATION_TYPES.map(durationType => ({
                                value: durationType.key,
                                label: durationType.label,
                            })),
                        }, {
                            isVisible: Boolean(fromSelectObject(formState.periodType) === OFFER_PERIOD_TYPE_RECURRING),
                            type: 'input',
                            name: 'noticePeriodDurationValue',
                            label: 'Czas trwania wypowiedzenia (Wartość)',
                            inputProps: {
                                type: 'number',
                            },
                        }, {
                            type: 'select',
                            name: 'invoicingStrategyId',
                            label: 'Strategia fakturowania',
                            inputProps: {
                                dynamic: true,
                                onLoad: query => actions.listInvoicingStrategies(),
                                onMapResponse: response => response.payload[API_RESOURCE_INVOICING_STRATEGIES].elements,
                                onMapOption: element => ({
                                    value: element.id,
                                    label: `${element.name} (${element.description})`,
                                }),
                            },
                        }, {
                            type: 'select',
                            name: 'firstPaymentMethodType',
                            label: 'Typ pierwszej płatności',
                            options: OFFER_FIRST_PAYMENT_METHOD_TYPES.map(type => ({
                                value: type.key,
                                label: type.label,
                            })),
                        }, {
                            type: 'switch',
                            name: 'suggestedForTrainer',
                            label: 'Sugerowana dla trenera?',
                        }, {
                            type: 's3FileUpload',
                            name: 'background',
                            label: 'Tło',
                            isVisible: Boolean(data),
                            inputProps: {
                                action: actions.presignFile,
                                s3Config: {
                                    presignPath: withVariables(ENDPOINT_ADMIN_OFFER_PRESIGN_BACKGROUND, { id: data && data.id }),
                                },
                            },
                        }],
                    }]}
                />
            </StyledComponent>
        );
    }
}