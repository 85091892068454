import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_OFFER_PRODUCTS_MANAGE } from 'Consts/routes';

import { parseQueryToObject } from 'Utils/querystring';
import { filterKeys } from 'Utils/object';
import { withVariables } from 'Utils/string';
import { formatPrice } from 'Utils/math';
import { getName as getProductName, getDurationTypeLabel } from 'Utils/product';
import { getName as getOfferName } from 'Utils/offer';
import { translateTimeUnit } from 'Utils/date';

import StyledComponent from 'Components/core/StyledComponent';
import PaginatedList from 'Components/layoutAdmin/panel/PaginatedList';
import PaginatedListElement from 'Components/layoutAdmin/panel/PaginatedListElement';

export default class PanelOfferProductsList extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            list: PropTypes.func.isRequired,
        }).isRequired,
        offerProducts: PropTypes.object.isRequired,
        predefinedQuery: PropTypes.object,
    };
    static defaultProps = {
        predefinedQuery: {},
    };

    defaultQuery = {
        page: 1,
        search: '',
        orderBy: undefined,
        timestamp: undefined,
        ...this.props.predefinedQuery,  //eslint-disable-line react/destructuring-assignment
        orderByDirection: undefined,
    };

    getQueryConfig = (props = this.props) => {
        const { location } = props;
        const { search } = location;
        let queryObject = parseQueryToObject(search, true);

        if(queryObject.alpha) {
            queryObject.orderBy = 'alpha';
            queryObject.orderByDirection = queryObject.alpha;
        }

        return filterKeys(
            this.defaultQuery,
            queryObject,
            Object.keys(this.defaultQuery)
        );
    }

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const previousQueryObject = this.getQueryConfig(prevProps);
        const queryObject = this.getQueryConfig();

        if (JSON.stringify(previousQueryObject) !== JSON.stringify(queryObject)) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();

        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
            actions.list({ ...queryObject });
        }, 500);
    }

    render() {
        const { offerProducts, location, history } = this.props;

        return (
            <StyledComponent
                className="panel-offer-product-list"
                styles={require('./styles')}
            >
                <PaginatedList
                    location={location}
                    history={history}
                    collection={offerProducts}
                    title="Lista produktów oferty"
                    queryConfig={this.getQueryConfig()}
                    onMapElement={element => (
                        <PaginatedListElement
                            key={element.id}
                            title={element.product && getProductName(element.product).label || 'Brak'}
                            subtitle={element.offer && getOfferName(element.offer).label || 'Brak'}
                            additionals={[{
                                name: 'Cena brutto',
                                value: formatPrice(element.priceGross),
                            }, {
                                name: 'Stawka VAT',
                                value: element.product && `${element.product.vatPercent}%` || 'Brak',
                            }, {
                                name: 'Ilość',
                                value: element.quantity,
                            }, {
                                name: 'Typ trwania',
                                value: element.product && getDurationTypeLabel(element.product).label,
                            }, {
                                name: 'Wartość trwania',
                                value: element.product && element.product.durationValue,
                            }, {
                                name: 'Wyświetlana wartość trwania',
                                value: element.product && element.product.displayDurationValue,
                            }, {
                                name: 'Typ wyświetlanej wartości trwania',
                                value: element.product && translateTimeUnit(element.product),
                            }]}
                            controls={[{
                                type: 'button',
                                label: 'Zarządzaj',
                                visible: true,
                                to: withVariables(ADMIN_OFFER_PRODUCTS_MANAGE.path, { id: element.id }),
                            }]}
                        />
                    )}
                    filters={[
                        {
                            name: 'search',
                            label: 'Szukaj',
                            type: 'text',
                        }, {
                            name: 'alpha',
                            label: 'Sortowanie alfabetyczne',
                            type: 'select',
                            inputProps: {
                                options: [{
                                    label: 'Według nazwy - rosnąco',
                                    value: 'ASC',
                                }, {
                                    label: 'Według nazwy - malejąco',
                                    value: 'DESC',
                                }],
                            },
                        }]}
                />
            </StyledComponent>
        );
    }
}
