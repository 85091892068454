import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_OFFERS, ADMIN_OFFERS_MANAGE, ADMIN_OFFER_USPS_CREATE } from 'Consts/routes';

import { withVariables } from 'Utils/string';

import { parseQueryToObject } from 'Utils/querystring';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import ViewHeader from 'Components/layout/panel/ViewHeader';
import Editor from 'Components/admin/offerUsps/Editor';
import { TAB_OFFERS } from 'Components/pages/admin/Offers/component';
import { TAB_USPS_LIST } from 'Components/pages/admin/OffersManage/component';

export default class AdminOfferUspsCreate extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };
    static defaultProps = {};

    render() {
        const { location, history } = this.props;
        const { search } = location;
        const queryObject = parseQueryToObject(search);

        return (
            <StyledComponent
                className="admin-offer-usps-create"
                styles={require('./styles')}
            >
                <PageContent
                    breadcrumbs={[{
                        to: withVariables(ADMIN_OFFERS.path, {}, { tab: TAB_OFFERS }),
                        label: 'Oferty',
                    }, {
                        to: withVariables(ADMIN_OFFERS_MANAGE.path, { id: queryObject.offerId }, { tab: TAB_USPS_LIST }),
                        label: 'Edytuj ofertę',
                    },{
                        to: withVariables(ADMIN_OFFER_USPS_CREATE.path, {}, { offerId: queryObject.offerId }),
                        label: 'Nowy USP zamówienia',
                    }]}
                >
                    <ViewHeader
                        title="Nowe USP zamówienia"
                    />
                    <Editor
                        location={location}
                        history={history}
                        offerId={queryObject.offerId || undefined}
                    />
                </PageContent>
            </StyledComponent>
        );
    }
}