import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { API_RESOURCE_OFFER_USP, API_RESOURCE_OFFERS } from 'Consts/apiResources';
import { ADMIN_OFFER_USPS_MANAGE, ADMIN_OFFERS_MANAGE } from 'Consts/routes';

import { withVariables } from 'Utils/string';
import { getName as getOfferName } from 'Utils/offer';
import { fromSelectObject } from 'Utils/object';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layoutAdmin/Spinner';
import ElementEditor from 'Components/layoutAdmin/panel/ElementEditor';

export default class PanelOfferUspsEditor extends Component {
    static propTypes = {
        offerId: PropTypes.string,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            create: PropTypes.func.isRequired,
            update: PropTypes.func.isRequired,
            destroy: PropTypes.func.isRequired,
            listOffers: PropTypes.func.isRequired,
            listProducts: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object,
    };
    static defaultProps = {
        data: null,
    };

    state = {
        formState: this.props.data || {},  //eslint-disable-line react/destructuring-assignment
    };

    componentDidUpdate = prevProps => {
        const { data } = this.props;

        if (data && JSON.stringify(data) !== JSON.stringify(prevProps.data)) {
            this.setState(prevState => ({
                formState: {
                    ...prevState.formState,
                    ...data,
                },
            }));
        }
    }

    onSubmit = formState => {
        const { data } = this.props;

        return data && data.id
            ? this.onUpdate(formState)
            : this.onCreate(formState);
    }

    onCreate = formState => {
        const { offerId, actions, history } = this.props;

        return actions.create({
            ...formState,
            offerId: offerId || fromSelectObject(formState.offerId),
        })
            .then(response => {
                history.push(
                    withVariables(
                        ADMIN_OFFER_USPS_MANAGE.path,
                        { id: response.payload[API_RESOURCE_OFFER_USP].id }
                    )
                );
            });
    }

    onUpdate = formState => {
        const { offerId, data, actions } = this.props;

        return actions.update({
            ...formState,
            id: data.id,
            offerId: offerId || fromSelectObject(formState.offerId),
        });
    }

    onDestroy = () => {
        const { data, actions, history } = this.props;

        return actions.destroy({ id: data.id })
            .then(response => {
                history.push(
                    withVariables(
                        ADMIN_OFFERS_MANAGE.path,
                        { id: data.offer && data.offer.id }
                    )
                );
            });
    }

    render() {
        const { offerId, data, location, history, actions } = this.props;
        const { formState } = this.state;

        if (!formState) {
            return (<Spinner />);
        }

        return (
            <StyledComponent
                className="panel-offer-usps-editor"
                styles={require('./styles')}
            >
                <ElementEditor
                    location={location}
                    history={history}
                    details={[{
                        visible: Boolean(data && data.id),
                        label: 'id',
                        value: formState.id,
                    }, {
                        visible: Boolean(data && data.id),
                        label: 'pozycja',
                        value: formState.order,
                    }]}
                    controls={[{
                        visible: Boolean(data && data.id),
                        title: 'Usuń USP z zamówienia',
                        subtitle: 'USP zostanie usunięte',
                        buttonProps: {
                            onClick: this.onDestroy,
                            children: 'Usuń',
                            confirm: {
                                enabled: true,
                            },
                        },
                    }]}
                    forms={[{
                        title: 'Dane',
                        name: 'editor',
                        submitAction: this.onSubmit,
                        data: formState,
                        onStateChange: formState => {
                            this.setState({
                                formState,
                            });
                        },
                        elements: [{
                            isVisible: Boolean(!data && !offerId),
                            type: 'select',
                            name: 'offerId',
                            label: 'Oferta',
                            inputProps: {
                                dynamic: true,
                                onLoad: query => actions.listOffers({ search: query }),
                                onMapResponse: response => response.payload[API_RESOURCE_OFFERS].elements,
                                onMapOption: element => ({
                                    value: element.id,
                                    label: getOfferName(element).label,
                                }),
                            },
                        }, {
                            type: 'textarea',
                            name: 'content',
                            label: 'Zawartość',
                        }],
                    }]}
                />
            </StyledComponent>
        );
    }
}