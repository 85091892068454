import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
    ADMIN_OFFER_PRODUCTS_CREATE,
    ADMIN_OFFER_USPS_CREATE,
    ADMIN_OFFERS,
    ADMIN_OFFERS_MANAGE,
} from 'Consts/routes';
import { ACTIVITY_LOGS_DATA_TYPE_OFFER } from 'Consts/activityLogs';

import { withVariables } from 'Utils/string';
import { parseQueryToObject } from 'Utils/querystring';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import Page from 'Components/layout/panel/Page';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import Editor from 'Components/admin/offers/Editor';
import OfferProductsList from 'Components/admin/offerProducts/List';
import OfferUspsList from 'Components/admin/offerUsps/List';
import { TAB_OFFERS } from 'Components/pages/admin/Offers/component';
import LogsList from 'Components/admin/activityLogs/List';

export const TAB_DATA = 'data';
export const TAB_PRODUCTS_LIST = 'productsList';
export const TAB_USPS_LIST = 'uspsList';
export const TAB_OFFER_LOGS = 'offerLogs';

export default class AdminOffersManage extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                id: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        actions: PropTypes.shape({
            single: PropTypes.func.isRequired,
        }).isRequired,
        offer: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            isLoaded: PropTypes.bool.isRequired,
            data: PropTypes.object,
        }),
    };
    static defaultProps = {};
    state = {};

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const { match } = this.props;
        const { params } = match;

        if (prevProps.match.params.id !== params.id) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions, match } = this.props;
        const { params } = match;

        actions.single({ id: params.id });
    }

    render() {
        const { offer, location, history } = this.props;
        const { search } = location;
        const queryObject = parseQueryToObject(search);

        return (
            <StyledComponent
                className="admin-offers-manage"
                styles={require('./styles')}
            >
                <Page
                    elementMode={true}
                    data={offer}
                >
                    {offer && offer.data && (
                        <PageContent
                            breadcrumbs={[{
                                to: withVariables(ADMIN_OFFERS.path),
                                label: 'Oferty',
                            }, {
                                to: withVariables(ADMIN_OFFERS.path, {}, { tab: TAB_OFFERS }),
                                label: 'Lista ofert',
                            }, {
                                to: withVariables(ADMIN_OFFERS_MANAGE.path, { id: offer.data.id }, { tab: TAB_DATA }),
                                label: `Edytuj ofertę: ${ offer.data.name }`,
                            }]}
                        >
                        
                            <TabNavigation
                                location={location}
                                history={history}
                                headline="Edytuj ofertę"
                                subHeadline={offer.data.name}
                                controls={[{
                                    visible: Boolean(queryObject.tab === 'productsList'),
                                    key: 'createProduct',
                                    label: 'Przypisz produkt',
                                    onClick: () => history.push(
                                        withVariables(
                                            ADMIN_OFFER_PRODUCTS_CREATE.path,
                                            {},
                                            { offerId: offer.data.id }
                                        )
                                    ),
                                }, {
                                    visible: Boolean(queryObject.tab === 'uspsList'),
                                    key: 'createUsp',
                                    label: 'Nowe USP',
                                    onClick: () => history.push(
                                        withVariables(
                                            ADMIN_OFFER_USPS_CREATE.path,
                                            {},
                                            { offerId: offer.data.id }
                                        )
                                    ),
                                }]}
                                tabs={[{
                                    key: TAB_DATA,
                                    label: 'Dane',
                                    children: (
                                        <Editor
                                            location={location}
                                            history={history}
                                            data={offer.data}
                                        />
                                    ),
                                }, {
                                    key: TAB_PRODUCTS_LIST,
                                    label: 'Produkty',
                                    children: (
                                        <OfferProductsList
                                            location={location}
                                            history={history}
                                            data={offer.data}
                                            predefinedQuery={{
                                                offerId: offer.data.id,
                                            }}
                                        />
                                    ),
                                }, {
                                    key: TAB_USPS_LIST,
                                    label: 'USPs',
                                    children: (
                                        <OfferUspsList
                                            location={location}
                                            history={history}
                                            data={offer.data}
                                            predefinedQuery={{
                                                offerId: offer.data.id,
                                            }}
                                        />
                                    ),
                                }, {
                                    visible: true,
                                    key: TAB_OFFER_LOGS,
                                    label: 'Logi',
                                    children: (
                                        <LogsList 
                                            location={location}
                                            history={history}
                                            predefinedQuery={{
                                                dataType: ACTIVITY_LOGS_DATA_TYPE_OFFER,
                                                dataId: offer.data.id || undefined,
                                            }}
                                            onMapFilters={() => []}
                                        />
                                    ),
                                }]}
                            />
                        
                        </PageContent>
                    )}
                </Page>
            </StyledComponent>
        );
    }
}

